<!-- =========================================================================================
  File Name: AttachmentView.vue
  Description: Attachment View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/attachment/pixinvent
========================================================================================== -->

<template>
  <div id="page-attachment-view">

    <vs-alert :active.sync="attachment_not_found" color="danger" title="Attachment Not Found">
      <span>Attachment record with id: {{ $route.params.attachmentId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link :to="{name:'page-attachment-list'}" class="text-inherit underline">All Attachments</router-link>
      </span>
    </vs-alert>


    <div v-if="attachment_data" id="attachment-data">
      <div class="vx-row">

        <div class="vx-col lg:w-1/5 w-full">
          <vx-card class="mb-base" style="height:95%" title="Attachment Actions">
            <!-- Avatar -->
            <div class="vx-row">

              <div class="vx-col w-full flex pt-3">
                <vs-button :to="`/a/attachments/${$route.params.attachmentId}/edit`" class="w-full" color="warning"
                           icon="icon-edit" icon-pack="feather" style="height:2rem;">
                  Edit
                </vs-button>
              </div>

              <!-- /Information - Col 2 -->
              <div class="vx-col w-full flex pt-3">
                <vs-button class="w-full" color="danger" icon="icon-archive" icon-pack="feather" style="height:2rem;" @click="deleteAttachment">
                  Delete
                </vs-button>
              </div>

            </div>
          </vx-card>
        </div>

        <div class="vx-col lg:w-4/5 w-full">
          <vx-card class="mb-base" style="height:95%" title="Extended Information">
            <table>
              <tr>
                <td class="font-semibold">Attachment Name</td>
                <td>{{ attachment_data.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Attachment Type</td>
                <td>{{ attachment_data.type | title }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Attachment Target</td>
                <td>{{ attachment_data.target }}</td>
              </tr>
            </table>
          </vx-card>
        </div>


      </div>


    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      attachment_data: null,
      attachment_not_found: false,
      attributes: {},

      treeData: [],
    };
  },
  methods: {
    deleteAttachment() {
      return this.$vs.notify({
        title: 'Failed to Delete Attachment',
        text: 'We do not currently support attachment deletion',
        color: 'danger',
      });
    },
    showError(exception) {

      let error = 'An unknown error occurred while modifying this attachment';
      if (exception.response) {
        error = exception.response.data.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to update attachment',
        text: error,
        color: 'danger',
      });
    },
  },
  created() {

    const attachmentId = this.$route.params.attachmentId;

    this.$http.get(`attachments/${attachmentId}`)
      .then(response => {

        if (response.data.data) {
          this.attachment_data = response.data.data;
        }

      })
      .catch(error => {
        console.error(error);

        if (error.response && error.response.status === 404) {
          this.attachment_not_found = true;
          return;
        }
      });
  },
};

</script>

<style lang="scss">
#avatar-col {
  width: 100%;
}

#page-attachment-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}
</style>
